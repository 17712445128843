import styled from 'styled-components';

export const ErrorMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem;
  text-align: center;

  & > button {
    width: min(20rem, 100%);
  }
`;

export const ImageContainer = styled.div`
  margin: 3.2rem 0 3.2rem;
  position: relative;
  height: 50rem;
  width: min(50rem, 100%);
`;
