import Image from 'next/image';
import { useRouter } from 'next/router';

import type { FC } from 'react';

import { ErrorMain, ImageContainer } from './error-page.styles';

import Button from '../button/button.component';

const ErrorPage: FC<{ code: 404 | 500 }> = ({ code }) => {
  const router = useRouter();

  const errorMessage =
    code === 500
      ? 'Something went really wrong'
      : 'That page is foreign to us.';

  const altText =
    code === 500
      ? 'internal server error illustration'
      : 'not found error illustration';

  return (
    <ErrorMain>
      <h1>Uh oh!!! {errorMessage}</h1>

      <ImageContainer>
        <Image
          src={`/${code}.svg`}
          alt={altText}
          layout="fill"
          objectFit="contain"
        />
      </ImageContainer>

      <Button onClick={() => router.push('/')}>Go home</Button>
    </ErrorMain>
  );
};

export default ErrorPage;
